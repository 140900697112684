// Body
$body-bg: #fff;
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// Colors
$yellow: #fc3;
$red: #d1694a;
$green: #41b691;
$blue: #0083cb;
$light-blue: #5bc0de;
$dark-blue: darken($blue, 10%);
$dark: #4d4e4e;
$grey: #3f3f3f;
$grey-light: #696969;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: transparent;
$navbar-default-toggle-border-color: transparent;
$navbar-default-toggle-bg: white;
$navbar-default-toggle-hover-bg: $dark-blue;
$navbar-default-toggle-icon-bar-bg: white;

// Brands
$brand-primary: $blue;
$brand-info: $light-blue;
$brand-success: $green;
$brand-warning: $yellow;
$brand-danger: $red;

// Typography
$font-family-sans-serif: sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #333;
$text-color-blue: $blue;
$headings-font-weight: 400;
$font-size-h1: 28px;
$font-size-h2: 22px;
$font-size-h3: 20px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;

$font-size-h1-sm: 28px;
$font-size-h2-sm: 22px;
$font-size-h3-sm: 20px;
$font-size-h4-sm: 16px;
$font-size-h5-sm: 14px;
$font-size-h6-sm: 12px;

// Navbar
$navbar-default-bg: transparent;
$navbar-default-link-color: #fff;
$navbar-default-link-hover-color: #fff;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #f1f1f1;

// Buttons
$btn-border-radius-base: 0;
$btn-default-color: #444;
$btn-default-bg: transparent;
$btn-default-border: #ccc;
$btn-primary-color: #fff;
$btn-primary-bg: $blue;
$btn-primary-border: $blue;
$btn-success-color: #fff;
$btn-success-bg: $green;
$btn-success-border: $green;
$btn-info-color: #fff;
$btn-info-bg: $light-blue;
$btn-info-border: $light-blue;
$btn-warning-color: #fff;
$btn-warning-bg: $yellow;
$btn-warning-border: $yellow;
$btn-danger-color: #fff;
$btn-danger-bg: $red;
$btn-danger-border: $red;

// Alerts
$alert-default-text: #777;
$alert-default-bg: transparent;
$alert-default-border: #ccc;
$alert-primary-text: #fff;
$alert-primary-bg: $blue;
$alert-primary-border: $blue;
$alert-success-text: #fff;
$alert-success-bg: $green;
$alert-success-border: $green;
$alert-info-text: #fff;
$alert-info-bg: $light-blue;
$alert-info-border: $light-blue;
$alert-warning-text: #fff;
$alert-warning-bg: $yellow;
$alert-warning-border: $yellow;
$alert-danger-text: #fff;
$alert-danger-bg: $red;
$alert-danger-border: $red;

// Inputs
$input-border-color: $grey-light;
$input-border-focus: $grey;
$input-color-placeholder: lighten($text-color, 40%);
$input-height-small: 30px;
$input-height-base: 36px;
$input-border-radius: 0;
$state-danger-text: #ff6868;

// Panels
$panel-border-radius: 0;
$panel-inner-border: $laravel-border-color;
$panel-primary-heading-bg: $blue;
$panel-primary-border: transparent;
