// Fonts
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700+Montserrat:400,700);

// Variables
@import 'variables_admin';

@import 'node_modules/font-awesome/scss/font-awesome';

// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'node_modules/bootstrap-select/sass/bootstrap-select.scss';
@import 'node_modules/owl.carousel/src/scss/owl.carousel.scss';

// Theme
html
{
    width: 100%;
    height: 100%;
}
body
{
    height: 100%;
    padding-top: 80px;
    padding-left: 50px;
}
// Body toggled
body.toggled
{
    .push-menu
    {
        @extend .btn-default;
    }
    #sidebar
    {
        width: 220px;
        .text
        {
            width: 170px;
            padding-left: 10px;
        }
        li
        {
            a
            {
                button
                {
                    display: inline-flex;

                    opacity: 1;
                }
            }
        }
    }
    > .container-fluid,
    footer .container
    {
        padding-left: 190px;
    }
}
// /Body toggled
// Normalize
.btn
{
    @extend .transition;
}
.alert-info
{
    font-size: 12px;

    position: relative;

    padding: 8px 12px 7px 12px;

    cursor: pointer;
    text-align: justify;
}
.alert-info::after
{
    font-family: 'FontAwesome';

    position: absolute;
    top: 8px;
    right: 10px;

    content: '\f078';
}
.alert-info.collapsed::after
{
    content: '\f054';
}
.panel-primary
{
    border: 0;
    .panel-heading
    {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;

        color: #fff;
        background-color: $text-color-blue;
    }
}
.transition
{
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
}
.inline-table
{
    display: inline-table;
}
.radio
{
    margin-top: 0;
    margin-bottom: 0;
}
.line-34
{
    line-height: 34px;
}
.no-line-height
{
    line-height: 0;
}
.pagination
{
    margin: 0;
}
.charts-loader
{
    color: $text-color-blue;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child)
{
    border-right: 0;
}
.img-responsive
{
    display: inline-block;
}
.image
{
    position: relative;

    display: flex;
    float: left;

    width: 154px;
    height: 154px;

    border: 2px solid $blue;
    border-radius: 10%;

    align-items: center;
    justify-content: center;
}
.avatar
{
    img
    {
        display: inline-block;

        width: 154px;
        height: 154px;

        border: 2px solid $blue;
        border-radius: 10%;
    }
}
.fontawesome-icon-list
{
    line-height: 30px;
}
.longtext
{
    overflow: hidden;

    max-width: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.pin-icon
{
    line-height: 34px;

    position: relative;

    display: inline-block;

    width: 34px;
    height: 34px;

    text-align: center;

    color: #fff;
    border-radius: 50%;
    background-color: #666;
}
dl
{
    margin-bottom: 10px;
}
// /Normalize
// Nav
.navbar-default
{
    float: left;

    width: 100%;
    height: 40px;

    .push-menu
    {
        width: 50px;
        height: 50px;

        border: 0;
        border-radius: 0;
    }
    .navbar-brand
    {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;

        padding: 12px 40px;

        color: $text-color-blue;

        @extend .transition;
        i
        {
            font-size: 22px;
        }
        img
        {
            height: 30px;
        }
    }
    .nav
    {
         > li
        {
            border-right: 1px solid #ccc;
            &:first-child
            {
                border-left: 1px solid #ccc;
            }
        }
        &.navbar-right
        {
            margin-right: 0;
        }
    }
}
// /nav
// sidebar
#sidebar
{
    position: fixed;
    z-index: 100;
    top: 50px;
    left: 0;

    width: 50px;
    height: 100%;

    color: #f1f1f1;
    border-right: 1px solid $light-blue;
    background: $light-blue;

    @extend .transition;
    li
    {
        min-height: 4px;

        border-bottom: 1px solid #52adc8;
        &.menu-head
        {
            padding: 10px;
        }
        a
        {
            position: relative;

            display: inline-table;

            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 18px;

            text-decoration: none;

            color: #fff;

            @extend .transition;
            &.active,
            &:hover
            {
                background-color: rgba(0, 0, 0, .1);
                span,
                i
                {
                    color: #fff;
                }
            }
            &:after
            {
                position: absolute;
                top: -1px;
                left: 0;

                width: 3px;
                height: 44px;

                content: '';

                background-color: transparent;

                @extend .transition;
            }
            &.active:after,
            &:hover:after
            {
                background-color: #568390;
            }
            &.active:before
            {
                position: absolute;
                right: -7px;

                width: 0;
                height: 0;
                margin-top: -10px;

                content: '';

                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 7px solid #52adc8;
            }
            span
            {
                display: inline-flex;
                overflow: hidden;

                width: 0;
                padding-left: 0;

                white-space: nowrap;

                @extend .transition;
            }
            i
            {
                width: 15px;
                margin-top: 4px;

                text-align: center;
            }
            button
            {
                position: absolute;
                top: 0;
                right: 0;

                display: none;

                width: 30px;
                height: 42px;
                margin: 0;
                padding: 0 0 0 10px;

                text-align: center;

                opacity: 0;
                border-radius: 0;
                background-color: rgba(0,0,0,.1);
            }
        }
         > ul > li
        {
            border-top: 1px solid #52adc8;
            border-bottom: 0;
        }
    }
    &:hover
    {
        width: 220px;
        .text
        {
            width: 170px;
            padding-left: 10px;
        }
        li
        {
            a
            {
                button
                {
                    display: inline-flex;

                    opacity: 1;
                }
            }
        }
    }
}
// /sidebar
// contents
#content
{
    float: left;

    width: 100%;
    height: 100%;
}
// maps
#block-maps
{
    float: left;

    width: 100%;
    height: 300px;
}
// maps
// Lists
#filter_limit
{
    display: inline-block;

    width: auto;
}
.filter-limit,
.filter-search
{
    max-width: 200px;
}
table.filter-table
{
    margin: 10px 0;
    thead
    {
        tr
        {
            th.sorting,
            th.sorting-asc,
            th.sorting-desc
            {
                position: relative;

                cursor: pointer;
            }
            th.sorting-asc,
            th.sorting-desc
            {
                border-color: $blue;
            }
            th.sorting:hover,
            th.sorting-asc:hover,
            th.sorting-desc:hover
            {
                background-color: #f5f5f5;
            }
            th.sorting:after,
            th.sorting-asc:after,
            th.sorting-desc:after
            {
                font-family: 'FontAwesome';

                position: absolute;
                right: 5px;
                bottom: 8px;

                display: block;

                opacity: .5;
            }
            th.sorting-desc:after
            {
                content: '\f175';
            }
            th.sorting-asc:after
            {
                content: '\f176';
            }
            th.sorting:after
            {
                content: '\f07d';

                opacity: .2;
            }
        }
    }
    tbody
    {
        tr
        {
            td,
            th
            {
                line-height: 37px;

                vertical-align: middle;
            }
            td .btn
            {
                line-height: 23px;

                height: 38px;
            }
        }
        tr.disable > td
        {
            opacity: .6;
        }
        tr.disable > td:last-child
        {
            opacity: 1;
        }
    }
}
.table-files > tbody > tr > td
{
    vertical-align: middle;

    .radio-styled.inline,
    .checkbox-styled.inline
    {
        margin-top: -13px;
    }
}
// /Lists
// /contents
// /theme

// Login
#login
{
    padding-left: 0;

    background-image: url('../images/bgs/bg_login_1.jpg');
    background-position: center;
    background-size: cover;
    .checkbox-styled
    {
        padding: 0;
    }
    footer
    {
        font-weight: 500;

        color: #fff;
        text-shadow: 1px 1px 1px #000;
    }
}
// /Login

// Forms
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline
{
    margin-left: 0;
}
.radio-styled,
.checkbox-styled
{
    margin-left: 0;
    padding: 7px 15px;
    &.inline
    {
        padding: 2px 15px;
    }
    &.block
    {
        padding: 7px 0;

    }
    input[type='radio'],
    input[type='checkbox']
    {
        z-index: 1;

        margin-left: 0;

        cursor: pointer;

        opacity: 0;
    }
    label
    {
        font-weight: 400;

        position: relative;

        padding-left: 22px;

        cursor: pointer;
        &:before
        {
            position: absolute;
            top: 2px;
            left: 0;

            display: inline-block;

            width: 17px;
            height: 17px;

            content: '';
            cursor: pointer;
            -webkit-transition: border .15s ease-in-out;
                    transition: border .15s ease-in-out;

            border: 2px solid #c4c4c4;
            border-radius: 50%;
            background-color: #fff;
        }
        &:after
        {
            position: absolute;
            top: 5px;
            left: 3px;

            display: inline-block;

            width: 11px;
            height: 11px;

            content: ' ';
            cursor: pointer;
            -webkit-transform: scale(0, 0);
                    transform: scale(0, 0);

            border-radius: 50%;
            background-color: #555;

            @extend .transition;
        }
    }
    input[type='radio']:checked + label::after,
    input[type='checkbox']:checked + label::after
    {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}
.checkbox-styled
{
    label:before,
    label:after
    {
        border-radius: 0;
    }
}

.radio-styled.radio-primary label:after,
.checkbox-styled.checkbox-primary label:after
{
    background-color: $blue;
}
.radio-styled.radio-primary label:before,
.checkbox-styled.checkbox-primary label:before
{
    border-color: $blue;
}

.radio-styled.radio-success label:after,
.checkbox-styled.checkbox-success label:after
{
    background-color: $green;
}
.radio-styled.radio-success label:before,
.checkbox-styled.checkbox-success label:before
{
    border-color: $green;
}

.radio-styled.radio-info label:after,
.checkbox-styled.checkbox-info label:after
{
    background-color: $light-blue;
}
.radio-styled.radio-info label:before,
.checkbox-styled.checkbox-info label:before
{
    border-color: $light-blue;
}

.radio-styled.radio-warning label:after,
.checkbox-styled.checkbox-warning label:after
{
    background-color: $yellow;
}
.radio-styled.radio-warning label:before,
.checkbox-styled.checkbox-warning label:before
{
    border-color: $yellow;
}

.radio-styled.radio-danger label:after,
.checkbox-styled.checkbox-danger label:after
{
    background-color: $red;
}
.radio-styled.radio-danger label:before,
.checkbox-styled.checkbox-danger label:before
{
    border-color: $red;
}

.status
{
    label
    {
        margin: 0;
    }
    .radio-styled
    {
        margin-left: 0;
        padding: 0 5px;
    }
}

.datepicker
{
    top: 0;
    left: 0;

    margin-top: 1px;
    padding: 4px;

    border-radius: 4px;
    &:before
    {
        position: absolute;
        top: -7px;
        left: 6px;

        display: inline-block;

        content: '';

        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-bottom-color: rgba(0, 0, 0, .2);
        border-left: 7px solid transparent;
    }
    &:after
    {
        position: absolute;
        top: -6px;
        left: 7px;

        display: inline-block;

        content: '';

        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
     > div
    {
        display: none;
    }
    table
    {
        width: 100%;
        margin: 0;
    }
    td,
    th
    {
        width: 20px;
        height: 20px;

        text-align: center;

        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
    }
    td
    {
        &.day
        {
            &:hover
            {
                cursor: pointer;

                background: #eee;
            }
            &.disabled
            {
                color: #eee;
            }
        }
        &.old,
        &.new
        {
            color: #999;
        }
        &.active
        {
            color: #fff;
            color: #fff;
            border-color: #04c #04c #002a80;
            border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
            background-color: #006dcc;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
            &:hover
            {
                color: #fff;
                color: #fff;
                color: #fff;
                border-color: #04c #04c #002a80;
                border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
                background-color: #006dcc;
                background-color: #04c;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);

                // background-color: #003bb3;
            }
            &:focus,
            &:hover:focus,
            &:active,
            &:hover:active,
            &.active,
            &:hover.active,
            &.disabled,
            &:hover.disabled,
            &[disabled],
            &:hover[disabled]
            {
                color: #fff;
                background-color: #04c;

                // background-color: #003bb3;
            }
            &:active,
            &:hover:active,
            &.active,
            &:hover.active
            {
                background-color: #039 \9;
            }
        }
        span
        {
            line-height: 54px;

            display: block;
            float: left;

            width: 47px;
            height: 54px;
            margin: 2px;

            cursor: pointer;

            border-radius: 4px;
            &:hover
            {
                background: #eee;
            }
            &.active
            {
                color: #fff;
                color: #fff;
                border-color: #04c #04c #002a80;
                border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
                background-color: #006dcc;
                &:hover,
                &:focus,
                &:active,
                &.active,
                &.disabled,
                &[disabled]
                {
                    color: #fff;
                    background-color: #04c;
                }
                &:active,
                &.active
                {
                    background-color: #039;
                }
            }
            &.old
            {
                color: #999;
            }
        }
    }
    th
    {
        &.switch
        {
            width: 145px;
        }
        &.next,
        &.prev
        {
            font-size: 21px;
        }
    }
    thead tr:first-child th
    {
        cursor: pointer;
        &:hover
        {
            background: #eee;
        }
    }
}

.input-append.date .add-on i,
.input-prepend.date .add-on i
{
    display: block;

    width: 16px;
    height: 16px;

    cursor: pointer;
}
// /Forms
@import 'forms';
