// Body
$body-bg: #fff;

// Colors
$yellow: #fc3;
$red: #d1694a;
$green: #41b691;
$blue: #69a5d0;
$light-blue: #5bc0de;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: #e7e7e7;
$nav-tabs-border-color: #eee;
$nav-tabs-active-link-hover-border-color: #ccc;

// Brands
$brand-primary: $blue;
$brand-info: $light-blue;
$brand-success: $green;
$brand-warning: $yellow;
$brand-danger: $red;

// Typography
$font-family-sans-serif: 'Quicksand', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #444;
$text-color-blue: $blue;
$headings-font-weight: 400;
$font-size-h1: 36px;
$font-size-h2: 32px;
$font-size-h3: 28px;
$font-size-h4: 24px;
$font-size-h5: 22px;
$font-size-h6: 18px;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: #444;
$btn-default-bg: transparent;
$btn-default-border: #ccc;
$btn-primary-color: #fff;
$btn-primary-bg: $blue;
$btn-primary-border: $blue;
$btn-success-color: #fff;
$btn-success-bg: $green;
$btn-success-border: $green;
$btn-info-color: #fff;
$btn-info-bg: $light-blue;
$btn-info-border: $light-blue;
$btn-warning-color: #fff;
$btn-warning-bg: $yellow;
$btn-warning-border: $yellow;
$btn-danger-color: #fff;
$btn-danger-bg: $red;
$btn-danger-border: $red;

// Alerts
$alert-default-text: #777;
$alert-default-bg: transparent;
$alert-default-border: #ccc;
$alert-primary-text: #fff;
$alert-primary-bg: $blue;
$alert-primary-border: $blue;
$alert-success-text: #fff;
$alert-success-bg: $green;
$alert-success-border: $green;
$alert-info-text: #fff;
$alert-info-bg: $light-blue;
$alert-info-border: $light-blue;
$alert-warning-text: #fff;
$alert-warning-bg: $yellow;
$alert-warning-border: $yellow;
$alert-danger-text: #fff;
$alert-danger-bg: $red;
$alert-danger-border: $red;

// Inputs
$input-border: lighten($text-color, 50%);
$input-border-color: lighten($brand-primary, 5%);
$input-border-focus: lighten($brand-primary, 5%);
$input-color-placeholder: lighten($text-color, 50%);
$input-height-small: 30px;
$input-border-radius: 3px;

// Panels
$panel-border-radius: 0;
$panel-inner-border: $laravel-border-color;
$panel-primary-heading-bg: #fff;
$panel-primary-border: transparent;
